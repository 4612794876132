import request from "@/request/request-utils";

export default {
  // 字典
  enumView: () => request.getAction("/api/Task/GetTaoBaoTaskEnumView"),
  // 获取待接取任务列表
  waitReceiveTask: (data) =>
    request.postJson("/api/Task/GetTaoBaoTaskWaitReceivePageList", data),
  // 获取待接取默认任务列表
  defaultReceiveTask: (data) =>
    request.getAction("/api/Task/GetTaoBaoTaskWaitReceiveDefaultList", data),
  // 任务分页列表
  taskChild: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskChildPageList", params),
  // 任务状态数量
  taskStatusNumber: () =>
    request.getAction("/api/Task/GetTaoBaoTaskStatusCount"),
  // 任务详情
  taskDetail: (params) =>
    request.getAction("/api/Task/GetMemberTaoBaoTaskChildDetail", params),
  // 任务返款方式
  taskRefundType: (params) =>
    request.getAction("/api/Task/SetTaoBaoTaskRefundType", params),
  // 任务取消分页列表
  taskCancelLog: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskMemberCancelLogPageList", params),
  // 接取任务
  receiveTask: (data) => request.postJson("/api/Task/TaoBaoTaskReceive", data),
  // 取消任务
  cancelTask: (params) =>
    request.getAction("/api/Task/TaoBaoTaskCancel", params),
  // 确认任务收款收货
  confirmTask: (params) =>
    request.getAction("/api/Task/TaoBaoTaskConfirm", params),
  // 确认非试用任务完成
  confirmNotSYTask: (params) =>
    request.getAction("/api/Task/TaoBaoTaskConfirmNotShiYong", params),
  // 任务评价无法完成
  taskEvaluateUncomplete: (params) =>
    request.getAction("/api/Task/TaoBaoTaskEvaluateCannot", params),
  // 获取任务评价要求
  taskEvaluate: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskEvaluateList", params),
  // 获取操作校验指令
  checkOperateCmd: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskCheckCmd", params),
  // 获取支付校验指令
  checkPayCmd: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskPayCheckCmd", params),
  // 获取确认校验指令
  checkConfirmCmd: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskConfirmEvaluateCheckCmd", params),
  // 获取任务账单
  taskChildBill: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskChildBillList", params),
  // 已取消任务确认
  taskCancelConfirm: (params) =>
    request.getAction("/api/Task/TaoBaoTaskCancelConfirm", params),
};
