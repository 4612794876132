import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountToken: "",
    networkType: 2,

    currentAccount: {},
    accountBalance: 0,
    memberStatus: false,

    baseData: {},
    tbMember: [],
    receiveMember: [],
    allReceiveMember: false,

    inviteCode: "",
    inviteUrl: "",
    inviteQrCode: "",

    receiveTaskMember: {},

    userIntegral: 0,
    exchangeProduct: {},

    handleTaskChildId: "",

    taskReceive: false,
    taskCancel: false,
    taskTimeEnd: false,

    confirmResale: false,

    deviceInfo: {},
    deviceLocation: "",
    deviceTime: 0,

    assistanceInfo: "",
  },
  getters: {},
  mutations: {
    accountToken(state, accountToken) {
      state.accountToken = accountToken;
    },
    networkType(state, networkType) {
      state.networkType = networkType;
    },

    currentAccount(state, currentAccount) {
      state.currentAccount = currentAccount;
    },
    accountBalance(state, accountBalance) {
      state.accountBalance = accountBalance;
    },
    memberStatus(state, memberStatus) {
      state.memberStatus = memberStatus;
    },

    baseData(state, baseData) {
      state.baseData = baseData;
    },
    tbMember(state, tbMember) {
      state.tbMember = tbMember;
    },
    receiveMember(state, receiveMember) {
      state.receiveMember = receiveMember;
    },
    allReceiveMember(state, allReceiveMember) {
      state.allReceiveMember = allReceiveMember;
    },

    inviteCode(state, inviteCode) {
      state.inviteCode = inviteCode;
    },
    inviteUrl(state, inviteUrl) {
      state.inviteUrl = inviteUrl;
    },
    inviteQrCode(state, inviteQrCode) {
      state.inviteQrCode = inviteQrCode;
    },

    receiveTaskMember(state, receiveTaskMember) {
      state.receiveTaskMember = receiveTaskMember;
    },

    userIntegral(state, userIntegral) {
      state.userIntegral = userIntegral;
    },
    exchangeProduct(state, exchangeProduct) {
      state.exchangeProduct = exchangeProduct;
    },

    handleTaskChildId(state, handleTaskChildId) {
      state.handleTaskChildId = handleTaskChildId;
    },

    taskReceive(state, taskReceive) {
      state.taskReceive = taskReceive;
    },
    taskCancel(state, taskCancel) {
      state.taskCancel = taskCancel;
    },
    taskTimeEnd(state, taskTimeEnd) {
      state.taskTimeEnd = taskTimeEnd;
    },

    confirmResale(state, confirmResale) {
      state.confirmResale = confirmResale;
    },

    deviceInfo(state, deviceInfo) {
      state.deviceInfo = deviceInfo;
    },
    deviceLocation(state, deviceLocation) {
      state.deviceLocation = deviceLocation;
    },
    deviceTime(state, deviceTime) {
      state.deviceTime = deviceTime;
    },

    assistanceInfo(state, assistanceInfo) {
      state.assistanceInfo = assistanceInfo;
    },
  },
  actions: {},
  modules: {},
});
