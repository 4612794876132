import request from "@/request/request-utils";

export default {
  // 获取用户积分
  userIntegral: () => request.getAction("/api/Member/GetMyPointBalance"),
  // 获取积分明细
  integralList: (params) =>
    request.getAction("/api/Member/GetPointChangePageList", params),
  // 获取兑换商品数据
  productData: () => request.getAction("/api/PointShop/GetPointShopBaseData"),
  // 商品兑换
  productExchange: (params) =>
    request.getAction("/api/PointShop/ProductExchange", params),
  // 用户签到
  accountSignin: (data) => request.postJson("/api/PointShop/SignIn", data),
  // 下次签到时间
  signinTime: () =>
    request.getAction("/api/PointShop/GetNextSignInMilliseconds"),
  // 签到展示列表
  signinShow: () => request.getAction("/api/PointShop/GetSignInShow"),
  // 签到记录列表
  signinRecord: (params) =>
    request.getAction("/api/PointShop/GetSignInPageList", params),
  // 兑换记录列表
  exchangeRecord: (params) =>
    request.getAction("/api/PointShop/GetProductExchangePageList", params),
  // 签到助力
  signinHelp: (params) =>
    request.getAction("/api/PointShop/SignInHelp", params),
  // 我的签到助力
  mineSigninHelp: (params) =>
    request.getAction("/api/PointShop/GetMySignInHelpPageList", params),
  // 好友签到助力
  friendSigninHelp: (params) =>
    request.getAction("/api/PointShop/GetToMeSignInHelpPageList", params),
};
