import request from "@/request/request-utils";

export default {
  // 登录
  login: (data) => request.postJson("/api/Default/Login", data),
  // 图形验证码
  verifyImage: () => request.getAction("/api/Default/GetImageVerify"),
  // 登出
  logout: () => request.getAction("/api/Default/Logout"),
  // 获取验证码
  sendValidCode: (params) =>
    request.getAction("/api/Default/SendValidCode", params),
  // 注册
  register: (data) => request.postData("/api/Default/Register", data),
  // 当前账号信息
  currentAccount: () => request.getAction("/api/Member/GetMyData"),
  // 修改密码
  changePassword: (params) =>
    request.getAction("/api/Member/ResetPassWord", params),
  // 设置收款码
  payCode: (data) => request.postData("/api/Member/SetPaymentCode", data),
  // 设置支付宝账号
  alipayAccount: (params) =>
    request.getAction("/api/Member/SetZhiFuBaoAccount", params),
  // 生成邀请码地址和二维码
  inviteCode: (params) =>
    request.getAction("/api/Member/GetInviteCode", params),
  // 下级会员邀请列表
  inviteMemberList: (params) =>
    request.getAction("/api/Member/GetMemberSubordinatePageList", params),
  // 下级会员状态
  inviteMemberStatus: (params) =>
    request.getAction("/api/Member/GetSubordinateToDayCount", params),
  // 下级商家邀请列表
  inviteBusinessList: (params) =>
    request.getAction("/api/Member/GetSellerSubordinatePageList", params),
  // 下级商家试用佣金加价
  markUp: (params) => request.getAction("/api/Member/SetMarkUp", params),
  // 邀请验证
  inviteMemberVerify: (params) =>
    request.getAction("/api/Member/ParentVerify", params),
  // 邀请人数
  inviteMemberCount: () => request.getAction("/api/Member/GetInviteCount"),
  // 获取个人邮箱详情
  getEmailInfo: () => request.getAction("/api/Member/GetMyEmailInfo"),
  // 获取邮箱验证码
  sendEmailCode: (params) =>
    request.getAction("/api/Default/SendEmailVerifyCode", params),
  // 设置邮箱
  setEmail: (params) => request.getAction("/api/Member/SetEmail", params),

  // 账户余额
  balanceData: () => request.getAction("/api/Member/GetMyBalance"),
  // 余额变更记录
  balanceList: (params) =>
    request.getAction("/api/Member/GetBalanceChangePageList", params),
  // 余额转售发布
  balanceSell: (params) =>
    request.getAction("/api/UserBalanceSell/Release", params),
  // 兑换积分
  integralExchange: (params) =>
    request.getAction("/api/Member/ExchangePoint", params),
  // 余额转售订单状态
  balanceResaleStatus: () =>
    request.getAction("/api/UserBalanceSell/GetBalanceSellStatusView"),
  // 余额转售订单列表
  balanceResaleList: (params) =>
    request.getAction("/api/UserBalanceSell/GetMySellPageList", params),
  // 余额转售取消
  balanceSellCancel: (params) =>
    request.getAction("/api/UserBalanceSell/Cancel", params),
  // 余额转售完成
  balanceSellConfirm: (params) =>
    request.getAction("/api/UserBalanceSell/Done", params),
  // 是否有余额转售订单已支付
  balancePayHave: () => request.getAction("/api/Member/IsHaveBalanceSellPay"),
  // 本月拉新
  monthsInvite: () => request.getAction("/api/Member/GetMonthsInvite"),
  // 我的商家收益
  monthsProfit: () =>
    request.getAction("/api/Member/GetProfitBySellerSubordinate"),
};
