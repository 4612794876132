import HttpRequest from "./http-request";
var httpRequest = new HttpRequest();

export default {
  getAction(url, params = {}, options = {}) {
    var request = {
      url: url,
      method: "get",
      params: params,
    };
    request = Object.assign(request, options);
    return httpRequest.request(request);
  },
  // post请求-Json数据格式
  postJson(url, data = {}, options = {}) {
    var request = {
      url: url,
      method: "post",
      data: data,
    };
    request = Object.assign(request, options);
    return httpRequest.request(request);
  },
  // post 上传
  postData(url, data) {
    return httpRequest.request({
      url: url,
      method: "post",
      data: data,
    });
  },
  // post请求-格式：x-www-form-urlencoded
  postForm(url, data = {}, options = {}) {
    var request = {
      url: url,
      method: "post",
      data: data,
    };
    request = Object.assign(request, options);
    return httpRequest.request(request);
  },
  // post 上传附件
  postFile(url, data) {
    return httpRequest.request({
      url: url,
      method: "post",
      data: data,
    });
  },
  // get 附件
  getFile(url) {
    return httpRequest.request({
      url: url,
      method: "get",
      responseType: "blob",
    });
  },
};
