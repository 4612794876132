import request from "@/request/request-utils";

export default {
  // 获取淘宝验号指令
  tbMemberCheck: (params) =>
    request.getAction("/api/Member/GetTaoBaoAccountCheckCmds", params),
  // 获取淘宝账号解绑
  tbMemberUnbind: (params) =>
    request.getAction("/api/Member/TaoBaoAccountUnbind", params),
  // 获取淘宝账号
  tbMember: () => request.getAction("/api/Member/GetTaoBaoAccountList"),
  // 获取淘宝地址
  tbAddress: (params) =>
    request.getAction("/api/Member/GetTaoBaoAddressList", params),
  // // 获取淘宝账号信息
  // tbAccount: (params) =>
  //   request.getAction("/api/Member/GetTaoBaoAccount", params),
};
