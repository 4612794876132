import request from "@/request/request-utils";

export default {
  // 获取最新APP版本号
  appVersion: (params) =>
    request.getAction("/api/Default/GetAppVersion", params, {
      responseType: "text",
    }),
  // 获取通知信息
  baseData: () => request.getAction("/api/Member/GetBaseData"),
  // 图片验证
  captchaVerify: (data) => request.postJson("/api/Default/CaptchaVerify", data),
  // 获取通知信息
  articleContent: (params) =>
    request.getAction("/api/Member/GetArticleContent", params),
  // 通知浏览次数
  articleBroswer: (params) =>
    request.getAction("/api/Member/SetArticleBrowser", params),
  // 通知支持
  articleSupport: (params) =>
    request.getAction("/api/Member/SetArticleIsSupport", params),
  // 获取教程列表
  tutorialList: (params) =>
    request.getAction("/api/Member/GetTutorialList", params),
  // // 获取重要通知信息
  // importanceNotice: () => request.getAction("/api/Default/ImportanceNotice"),
  // 保存设备信息
  userDevice: (data) => request.postJson("/api/Default/SaveDevice", data),
  // IP地址
  ipAddress: () => request.getAction("/api/Default/GetIpAddress"),
  // 反编译地理位置
  loactionAddress: (data) =>
    request.postForm(
      "https://jmgeocode.market.alicloudapi.com/geocode/regeo_query",
      data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "APPCODE c5b03f220c1249e6bef5a37ae3e8760e",
        },
      }
    ),
  // 获取线上前端版本
  networkVersion: (url) => request.getAction(url),
};
