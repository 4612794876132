import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index/IndexPage.vue"),
  },
  {
    path: "/v2/account/login",
    name: "AccountLogin",
    component: () => import("../views/AccountLogin.vue"),
  },
  {
    path: "/v2/account/register",
    name: "AccountRegister",
    component: () => import("../views/AccountRegister.vue"),
  },
  {
    path: "/v2/account/member",
    name: "AccountMember",
    component: () => import("../views/AccountMember.vue"),
  },
  {
    path: "/v2/task/handle",
    name: "TaskHandle",
    component: () => import("../views/task/TaskHandle.vue"),
  },
  {
    path: "/v2/account/balance",
    name: "AccountBalance",
    component: () => import("../views/balance/AccountBalance.vue"),
  },
  {
    path: "/v2/resale/balance",
    name: "ResaleBalance",
    component: () => import("../views/balance/ResaleBalance.vue"),
  },
  {
    path: "/v2/application/download",
    name: "ApplicationDownload",
    component: () => import("../views/ApplicationDownload.vue"),
  },
  {
    path: "/v2/member/apply",
    name: "MemberApply",
    component: () => import("../views/MemberApply.vue"),
  },
  {
    path: "/v2/invite/member",
    name: "InviteMember",
    component: () => import("../views/subordinate/InviteMember.vue"),
  },
  {
    path: "/v2/subordinate/member",
    name: "SubordinateMember",
    component: () => import("../views/subordinate/SubordinateMember.vue"),
  },
  {
    path: "/v2/invite/business",
    name: "InviteBusiness",
    component: () => import("../views/subordinate/InviteBusiness.vue"),
  },
  {
    path: "/v2/bank/card",
    name: "BankCard",
    component: () => import("../views/payment/BankCard.vue"),
  },
  {
    path: "/v2/my/wechat",
    name: "MyWechat",
    component: () => import("../views/payment/MyWechat.vue"),
  },
  {
    path: "/v2/my/alipay",
    name: "MyAlipay",
    component: () => import("../views/payment/MyAlipay.vue"),
  },
  {
    path: "/v2/account/tutorial",
    name: "AccountTutorial",
    component: () => import("../views/AccountTutorial.vue"),
  },
  {
    path: "/v2/tutorial/detail",
    name: "TutorialDetail",
    component: () => import("../views/TutorialDetail.vue"),
  },
  {
    path: "/v2/integral/market",
    name: "IntegralMarket",
    component: () => import("../views/integral/IntegralMarket.vue"),
  },
  {
    path: "/v2/integral/product",
    name: "IntegralProduct",
    component: () => import("../views/integral/IntegralProduct.vue"),
  },
  {
    path: "/v2/account/signin",
    name: "AccountSignin",
    component: () => import("../views/signin/AccountSignin.vue"),
  },
  {
    path: "/v2/signin/record",
    name: "SignInRecord",
    component: () => import("../views/signin/SignInRecord.vue"),
  },
  {
    path: "/v2/exchange/record",
    name: "ExchangeRecord",
    component: () => import("../views/integral/ExchangeRecord.vue"),
  },
  {
    path: "/v2/account/integral",
    name: "AccountIntegral",
    component: () => import("../views/integral/AccountIntegral.vue"),
  },
  {
    path: "/v2/account/assistance",
    name: "AccountAssistance",
    component: () => import("../views/signin/AccountAssistance.vue"),
  },
  {
    path: "/v2/rank/last",
    name: "RankLast",
    component: () => import("../views/rank/RankLast.vue"),
  },
  {
    path: "/v2/invite/profit",
    name: "InviteProfit",
    component: () => import("../views/InviteProfit.vue"),
  },
  {
    path: "/v2/receive/setting",
    name: "ReceiveSetting",
    component: () => import("../views/ReceiveSetting.vue"),
  },
  {
    path: "/v2/receive/tutorial",
    name: "ReceiveTutorial",
    component: () => import("../views/ReceiveTutorial.vue"),
  },
  {
    path: "/v2/my/email",
    name: "MyEmail",
    component: () => import("../views/MyEmail.vue"),
  },
  {
    path: "/v2/message/list",
    name: "MessageList",
    component: () => import("../views/message/MessageList.vue"),
  },
  {
    path: "/v2/rank/task",
    name: "RankTask",
    component: () => import("../views/rank/RankTask.vue"),
  },
  {
    path: "/v2/system/notice",
    name: "SystemNotice",
    component: () => import("../views/SystemNotice.vue"),
  },
  {
    path: "/v2/system/servicer",
    name: "SystemServicer",
    component: () => import("../views/SystemServicer.vue"),
  },
  {
    path: "/v2/months/invite",
    name: "MonthsInvite",
    component: () => import("../views/MonthsInvite.vue"),
  },
  {
    path: "/v2/months/business",
    name: "MonthsBusiness",
    component: () => import("../views/MonthsBusiness.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
