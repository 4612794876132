import { Toast } from "vant";

export default {
  /**
   * 解析地址携带的参数
   * @param {*} url 需要解析的地址
   * @returns 地址携带参数集合的对象
   */
  formatUrlQuery(url) {
    if (url.indexOf("?") > -1) {
      var querys = url.split("?")[1].split("&");
      if (querys.length > 0) {
        var result = {};
        querys.forEach((item) => {
          var qs = item.split("=");
          result[qs[0]] = qs[1];
        });
        return result;
      } else {
        return {};
      }
    } else {
      return {};
    }
  },

  /**
   * 根据规范格式化日期
   * @param {*} date 需要格式化的日期
   * @param {*} fmt 格式化的规范
   * @returns 格式化后的日期
   */
  dateFormat: function (date, fmt) {
    if (!date) {
      return;
    }
    if (typeof date == "string") {
      date = new Date(date);
    }
    var o = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return fmt;
  },
  /**
   * 计算两个日期间间隔的月数
   * @param {*} date1 开始日期
   * @param {*} date2 结束日期
   * @returns 间隔月数
   */
  monthNumber: function (date1, date2) {
    let dateOne = new Date(date1);
    let dateTwo = new Date(date2);
    // 第一个日期的年和月
    let yearOne = dateOne.getFullYear();
    let monthOne = dateOne.getMonth() + 1;
    // 第二个日期的年和月
    let yearTwo = dateTwo.getFullYear();
    let monthTwo = dateTwo.getMonth() + 1;
    // 两个日期的月份数
    let oneMonthNum = yearOne * 12 + monthOne;
    let twoMonthNum = yearTwo * 12 + monthTwo;
    return Math.abs(oneMonthNum - twoMonthNum);
  },
  /**
   * 计算两个日期间间隔的天数
   * @param {*} date1 开始日期
   * @param {*} date2 结束日期
   * @returns 间隔天数
   */
  dateNumber: function (date1, date2) {
    // 计算两个日期之间的差值
    let dateOne = new Date(date1);
    let dateTwo = new Date(date2);
    // 将两个日期都转换为毫秒格式，然后做差
    let diffDate = Math.abs(dateOne - dateTwo); // 取相差毫秒数的绝对值
    let diffDay = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
    return diffDay;
  },

  /**
   * 初始化阿里云验证码
   * @param {*} captchaVerifyCallback 业务请求(带验证码校验)回调函数
   * @param {*} onBizResultCallback 业务请求结果回调函数
   * @param {*} getInstance 绑定验证码实例函数
   */
  initAliyunCaptcha(
    elementId,
    buttonId,
    captchaVerifyCallback,
    onBizResultCallback,
    getInstance
  ) {
    window.initAliyunCaptcha({
      SceneId: "1qhchpml", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: "4zg8gm", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: elementId, // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: buttonId, // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      // slideStyle: {
      //   width: 360,
      //   height: 40,
      // }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    });
  },
  /**
   * 移除阿里云验证码弹窗
   */
  removeAliyunCaptcha: function () {
    // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
    var acmask = document.getElementById("aliyunCaptcha-mask");
    if (acmask) {
      acmask.remove();
    }

    var acpopup = document.getElementById("aliyunCaptcha-window-popup");
    if (acpopup) {
      acpopup.remove();
    }
  },

  /**
   * 动态JS
   * @param {*} src JS资源
   * @param {*} callback 回调方法
   */
  loadJS: function (src, callback) {
    const script = document.createElement("script");
    script.type = "text/JavaScript";
    script.src = src;
    if (script.addEventListener) {
      script.addEventListener("load", callback, false);
    }
    document.head.appendChild(script);
  },

  /**
   * 获取用户设备
   */
  userDevice() {
    let that = this;
    return new Promise((resolve, reject) => {
      try {
        if (that.appOS() !== "") {
          window.$bridge.deviceAppInfo(
            function (response) {
              response.systemVersion = response.systemVersion + "";
              response.deviceType = that.appOS() === "Android" ? "1" : "2";
              resolve(response);
            },
            function (error) {
              reject(error);
            }
          );
        } else {
          reject({});
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  /**
   * 用户定位
   */
  userLocation() {
    let that = this;
    return new Promise((resolve, reject) => {
      try {
        if (that.appOS() !== "") {
          window.$bridge.deviceLocation(
            function (location) {
              var latlng = location.location;
              resolve(latlng);
            },
            function (error) {
              reject(error);
            }
          );
        } else {
          reject({});
        }
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * 判断文本是否为空
   * @param {*} text 需要判断的文本
   * @returns true 文本为空 false 文本不为空
   */
  isEmptyText: function (text) {
    if (!text) {
      return true;
    }
    return false;
  },
  /**
   * 判断当前加载页面的设备系统类型
   * @returns 返回当前APP的系统
   */
  appOS: function () {
    if (typeof xwgs !== "undefined") {
      // 存在jla标签说明是Android“易优GO”APP
      return "Android";
    } else if (
      typeof window.webkit !== "undefined" &&
      typeof window.webkit.messageHandlers.deviceInfo !== "undefined"
    ) {
      // window.webkit是iOS的WKWebView自带框架
      // window.webkit.messageHandlers.deviceInfo是iOS“易优GO”APP自带的方法，是为了排除其他APP加载我们页面，如微信
      return "iOS";
    } else {
      return "";
    }
  },
  /**
   * 调试APP是需要显示的文本，统一接口，方便上架时可以统一清除
   * @param {*} text 显示文本
   */
  appTestToast: function (text) {
    Toast.fail(text);
  },
};
