<template>
  <div
    id="app"
    :style="`${
      $utils.appOS() === 'iOS' ? 'padding-top: 0px; height: 100%;' : ''
    }`"
  >
    <!-- <img class="bgone" src="./assets/image/bg/bgline1.png" />
    <img class="bgtwo" src="./assets/image/bg/bgline2.png" />
    <img class="bgthree" src="./assets/image/bg/bgline3.png" /> -->

    <keep-alive :include="['IndexPage']">
      <router-view />
    </keep-alive>

    <van-dialog
      title="助力提示"
      v-model="assistanceShow"
      show-cancel-button
      @confirm="onConfirmAssistance"
    >
      <div class="assistance-tip">检测到助力信息，是否前往助力？</div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      assistanceInfo: "",
      assistanceShow: false,
      assistanceTimer: null,
    };
  },
  created() {
    document.title = "易优GO会员平台";

    if (this.$route.path === "/static/contact.html") {
      return;
    }

    this.$router.beforeEach((to, from, next) => {
      const element = document.getElementById("LRfloater0");
      if (element) {
        if (
          to.name === "AccountLogin" ||
          to.name === "AccountRegister" ||
          to.name === "ApplicationDownload"
        ) {
          element.style.display = "block";
        } else {
          element.style.display = "none";
        }
      }
      next();
    });

    // localStorage.setItem(
    //   "AccountToken",
    //   "1764568116261687296:40401083-d564-49e0-8fe1-677832e244d5"
    // );

    let that = this;
    this.$toast.setDefaultOptions({
      duration: 5000,
      closeOnClick: true,
      closeOnClickOverlay: true,
      overlay: true,
    });

    // /**
    //  * 通过路由控制客服图标显隐
    //  */
    // this.$router.beforeEach((to, from, next) => {
    //   const element = document.getElementById("LRfloater0");
    //   if (element) {
    //     that.controlLRFHidden(to, element);
    //   } else {
    //     setTimeout(() => {
    //       const elementT = document.getElementById("LRfloater0");
    //       that.controlLRFHidden(to, elementT);
    //     }, 500);
    //   }
    //   next();
    // });

    var baseData = localStorage.getItem("baseData");
    if (baseData && baseData !== "") {
      this.$store.commit("baseData", JSON.parse(baseData));
    }
  },
  mounted() {
    if (this.$route.path === "/static/contact.html") {
      return;
    }

    this.setDefaultSetting();
  },
  methods: {
    /**
     * 设置默认设定
     */
    setDefaultSetting() {
      let that = this;

      /**
       * 通知设置项，铃声、震动
       */
      var notificationSound = localStorage.getItem("NotificationSound");
      if (this.$utils.isEmptyText(notificationSound)) {
        localStorage.setItem("NotificationSound", true);
      }
      var notificationVibrator = localStorage.getItem("NotificationVibrator");
      if (this.$utils.isEmptyText(notificationVibrator)) {
        localStorage.setItem("NotificationVibrator", true);
      }

      if (this.$utils.appOS() !== "") {
        /**
         * app显示弹窗
         */
        window.$bridge.appToast(
          (res) => {
            that.$toast.clear();
            that.$toast.success({ message: res });
          },
          (err) => {
            that.$toast.clear();
            that.$toast.fail({
              message: err,
              duration: 10000,
              closeOnClickOverlay: true,
              overlay: true,
            });
          },
          (msg) => {
            that.$toast.clear();
            that.$toast.loading({
              message: msg + "...",
              forbidClick: true,
              loadingType: "spinner",
              duration: 0,
            });
          }
        );

        /**
         * 应用状态回调，进入前后台事件
         */
        window.$bridge.applicationStatus(
          () => {
            // this.$toast.fail("application status foreground");
            // console.log("application status foreground");
            that.$bus.$emit("EnterForeground");
          },
          () => {
            // this.$toast.fail("application status background");
            // console.log("application status background");
            that.$bus.$emit("EnterBackground");
          }
        );
      }

      // if (this.$utils.appOS() === "Android") {
      //   window.$bridge.openPCAgent();
      // }

      // 签到助力，剪贴板读取
      var accountToken = localStorage.getItem("AccountToken");
      if (!this.$utils.isEmptyText(accountToken)) {
        this.checkApplicationClipboard();
      } else {
        if (this.$route.path !== "/v2/account/login") {
          this.$router.push({
            path: "/v2/account/login",
          });
        }
      }

      // 应用进入前台
      this.$bus.$on("EnterForeground", () => {
        var accountToken = localStorage.getItem("AccountToken");
        if (!that.$utils.isEmptyText(accountToken)) {
          that.checkApplicationClipboard();
        }
      });
      // 应用进入后台
      this.$bus.$on("EnterBackground", () => {});

      document.body.addEventListener("focusin", () => {
        //软键盘弹起事件
      });
      document.body.addEventListener("focusout", () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      });
    },

    controlLRFHidden(toUrl, ele) {
      if (ele) {
        if (
          toUrl.name === "AccountLogin" ||
          toUrl.name === "AccountRegister" ||
          toUrl.name === "ApplicationDownload"
        ) {
          ele.style.display = "block";
        } else {
          ele.style.display = "none";
        }
      }
    },

    checkApplicationClipboard() {
      if (this.assistanceTimer) {
        clearTimeout(this.assistanceTimer);
        this.assistanceTimer = null;
      }

      let that = this;
      this.assistanceTimer = setTimeout(() => {
        if (that.assistanceTimer) {
          clearTimeout(that.assistanceTimer);
          that.assistanceTimer = null;
        }
        if (that.$utils.appOS() !== "") {
          that.getClipboardText();
        }
      }, 8000);
    },
    getClipboardText() {
      let that = this;
      window.$bridge.clipboardText(function (response) {
        var previousAssistanceInfo = localStorage.getItem(
          "previousAssistanceInfo"
        );
        var previousAssistanceDate = localStorage.getItem(
          "previousAssistanceDate"
        );
        var currentDate = that.$utils.dateFormat(new Date(), "yyyy-MM-dd");

        if (
          !that.$utils.isEmptyText(response) &&
          (response !== previousAssistanceInfo ||
            previousAssistanceDate !== currentDate)
        ) {
          if (
            response.indexOf("易优GO签到助力🌷🌷🌷") === 0 &&
            response.indexOf("💎💎💎") !== -1
          ) {
            var inviteCode = response
              .replace("易优GO签到助力🌷🌷🌷", "")
              .replace("💎💎💎", "");
            if (inviteCode !== that.$store.state.inviteCode) {
              that.assistanceInfo = response;
              that.assistanceShow = true;
            }
          }
        }
      });
    },

    onConfirmAssistance() {
      if (this.$route.path !== "/v2/account/signin") {
        this.$store.commit("assistanceInfo", this.assistanceInfo);
        this.$router.push({
          path: "/v2/account/signin",
        });
      } else {
        this.$bus.$emit("FriendAssistance");
      }
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app {
  width: 100%;
  height: calc(100% - 20px);
  padding-top: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: $app-color-bg;

  .bgone {
    width: 157px;
    height: 373px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
    pointer-events: none;
  }

  .bgtwo {
    width: 336px;
    height: 284px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
    pointer-events: none;
  }

  .bgthree {
    width: 100%;
    position: absolute;
    right: 0px;
    top: 450px;
    z-index: 0;
    pointer-events: none;
  }
}

.color-error {
  color: $app-color-error !important;
}

.color-warning {
  color: $app-color-warning !important;
}

.color-primary {
  color: $app-color-primary !important;
}

.van-button--primary {
  background-color: $app-color-primary;
  border: none;
}

.van-button--success {
  background-color: $app-color-success;
  border: none;
}

.van-button--danger {
  background-color: $app-color-error;
  border: none;
}

.van-dialog__confirm,
.van-dialog__confirm:active {
  color: $app-color-primary;
  font-size: $app-dimen-font-big;
}

.van-dialog__cancel {
  color: $app-color-text-grey;
  font-size: $app-dimen-font-big;
}

.customer-service {
  width: 52px;
  height: 40px;
  border-radius: 28px 0px 0px 28px;
  background-color: $app-color-primary;
  position: fixed;
  right: 0px;
  bottom: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: $app-dimen-font-normal;
    color: white;
    line-height: 12px;
    transform: scale(0.8);
  }
}

#LRfloater0 {
  left: 5px !important;
  right: auto !important;
  top: auto !important;
  bottom: 100px !important;
}

img[src=""],
img:not([src]) {
  visibility: hidden;
}

.van-toast {
  width: auto;
  min-width: 88px;
  z-index: 999999999999 !important;
}

.assistance-tip {
  max-height: 520px;
  font-size: $app-dimen-font-big;
  color: $app-color-text;
  margin: 2px 14px 16px 14px;
  overflow: auto;
}

.van-dialog__content--isolated {
  min-height: 36px;
}

.van-dialog__message {
  padding: 23px 14px 16px;
  color: $app-color-text;
}

.help-tip {
  max-width: 230px;
  font-size: $app-dimen-font-normal;
  color: $app-color-text;
  margin: 5px 10px;
  word-break: break-all;
  display: block;
}

.help-info {
  width: 16px;
  height: 16px;
  font-size: $app-dimen-font-small;
  border-radius: 8px;
  line-height: 18px;
  text-align: center;
  color: $app-color-primary;
  margin-left: 8px;
  background-color: white;
  font-style: italic;
}

.popup-container {
  width: 100%;

  .popup-title {
    width: 100%;
    height: 44px;
    position: relative;

    .title {
      width: 100%;
      line-height: 44px;
      font-size: $app-dimen-font-biggest;
      font-weight: 500;
      text-align: center;
      color: $app-color-title;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
    }
  }

  .popup-content {
    width: 100%;
    max-height: 80vh;
    overflow: hidden auto;
  }
}
</style>
