import account from "./account.js";
import member from "./member.js";
import task from "./task.js";
import identification from "./identification.js";
import file from "./file.js";
import common from "./common.js";
import integral from "./integral.js";
import rank from "./rank.js";
import profit from "./profit.js";
import message from "./message.js";

export default {
  account,
  member,
  task,
  identification,
  file,
  common,
  integral,
  rank,
  profit,
  message,
};
