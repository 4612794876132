import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "babel-polyfill";

Vue.config.productionTip = false;

// 注册EventBus
Vue.prototype.$bus = new Vue();

import {
  Field,
  Button,
  Icon,
  Image,
  Toast,
  Tabbar,
  TabbarItem,
  Tabs,
  Tab,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Uploader,
  PullRefresh,
  CountDown,
  RadioGroup,
  Radio,
  NoticeBar,
  Swipe,
  SwipeItem,
  ActionSheet,
  Calendar,
  Switch,
  Checkbox,
  Popover,
  Popup,
  Picker,
} from "vant";
Vue.use(Field)
  .use(Button)
  .use(Icon)
  .use(Image)
  .use(Toast)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Tabs)
  .use(Tab)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Dialog)
  .use(Uploader)
  .use(PullRefresh)
  .use(CountDown)
  .use(RadioGroup)
  .use(Radio)
  .use(NoticeBar)
  .use(Swipe)
  .use(SwipeItem)
  .use(ActionSheet)
  .use(Calendar)
  .use(Switch)
  .use(Checkbox)
  .use(Popover)
  .use(Popup)
  .use(Picker);

import api from "./api";
Vue.prototype.$api = api;

import utils from "./utils/utils.js";
Vue.prototype.$utils = utils;

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
