import request from "@/request/request-utils";

export default {
  //收益banner列表
  profitBannerList: () => request.getAction("api/Member/GetProfitBannerList"),
  //我的收益
  myProfit: (params) => request.getAction("api/Member/GetMyProfit", params),
  //收益列表
  profitList: (params) => request.getAction("api/Member/GetProfitList", params),
};
