import request from "@/request/request-utils";

export default {
  // 活体认证
  liveIdentification: (params) =>
    request.getAction("/api/Member/InitFaceVerify", params),
  // 校验人脸验证结果
  liveIdentificationResult: () =>
    request.getAction("/api/Member/FaceVerifyResult"),
};
