import request from "@/request/request-utils";

export default {
  // 当前周排名
  weekRanking: () => request.getAction("/api/Task/GetTaoBaoTaskWeekRanking"),
  // 上一周排名
  lastRanking: () =>
    request.getAction("/api/Task/GetTaoBaoTaskLastWeekRanking"),
  // 邀请排名
  inviteRanking: () => request.getAction("api/member/GetInviteRanking"),
  // 签到排名
  signinRanking: () => request.getAction("api/member/GetSignInRanking"),
};
